import React from "react";
import "./Footer.css";

import {
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlineYoutube,
  AiOutlineFacebook,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Subscribe from "./Subscribe";
function Footer() {
  const navigate = useNavigate();
  return (
    <div>
      <footer className="footer bg-[#030000] text-white py-8 px-4 sm:px-6 lg:px-8">
        <Subscribe />
        <div className="footer__content flex flex-col md:flex-row items-start md:items-center justify-between max-w-screen-lg mx-auto">
          <div className="footer__addr mb-4 md:mb-0">
            <h1 className="footer__logo font-semibold text-xl mb-2">
              Raitagyana
            </h1>
            <div className="addressFooter">
              <address>
                <h2 className="font-semibold mb-1 sm:mb-2">Address</h2>
                <div className="opacity-80">
                  Rampure Colony,
                  <br />
                  Opp Pharmacy College,
                  <br />
                  BVB Road Bidar-585401
                </div>
              </address>
              <address>
                <h2 className="font-semibold  mb-2">Contact No.</h2>
                <div className="opacity-80">
                  +91-9611050055
                  <br />
                  +91-8951734819
                </div>
              </address>
            </div>
          </div>

          <div className="footer__contact-info flex flex-col md:flex-row md:space-x-6 items-start md:items-center">
            <div className="flex gap-20">
              <div className="nav__item">
                <h2 className="nav__title font-semibold mb-1 sm:mb-1">
                  About Raitagyana
                </h2>
                <ul className="nav__ul">
                  <li>
                    <Link to="/aboutus">
                      • Our Impact &{" "}
                      <span className="ml-[0.6rem] sm:ml-0">Mission </span>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="nav__item gap-[20px]">
                <h2 className="nav__title font-semibold mb-1 sm:mb-1">
                  Agricultural Legal
                </h2>
                <ul className="nav__ul ">
                  <li>
                    <a href="#" className="text-center">
                      • Terms of Agricultural{" "}
                      <span className="ml-[0.6rem] sm:ml-0">Use </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">• Farm Sitemap</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="legal mt-4 text-center text-sm">
          <p>&copy; 2024 Raitagyana. All rights reserved.</p>
          <div className="legal__links mt-2">
            <span>
              Cultivated with <span className="heart">♥</span> to build a strong
              community.
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
