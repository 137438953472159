import React, { useState, useEffect } from "react";
import CompoHeader from "../common/CompoHeader";
import volunteer from "../../images/volunteer.png";

import volunteer1 from "../../raitagyana-images/WhatsApp Image 2024-03-09 at 16.12.38_c7bb707e.jpg";
import volunteer2 from "../../raitagyana-images/WhatsApp Image 2024-03-09 at 16.13.00_1c759297.jpg";
import volunteer3 from "../../raitagyana-images/1A7CDA34-5438-4F18-A49B-5BF498B20A04.jpeg";
import "./Volunteer.css";

const Volunteer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleJoin = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSeCC4CdZ8n3KRIvrTo_LWVgKeDIwfRwaWAYhxm6gpQF3cDsNA/viewform",
      "_blank"
    );
  };

  return (
    <div>
      <section>
        <div>
          <CompoHeader name="Volunteer" image={volunteer} />
        </div>
      </section>
      <section class="grid grid-cols-1 md:grid-cols-2  mt-10 md:mt-20  mx-5 md:mx-10 rounded-lg shadow-2xl">
        <div class="flex justify-center">
          {isMobile ? (
            <img
              class="w-full h-[250px] md:h-[370px] object-cover rounded-tl-lg rounded-tr-lg"
              src={volunteer1}
              alt=""
            />
          ) : (
            <img
              class="w-full h-[250px] md:h-[370px] object-cover rounded-tl-lg rounded-bl-lg"
              src={volunteer1}
              alt=""
            />
          )}
        </div>
        <div class="flex flex-col justify-center ml-3 mb-3 md:ml-6 mr-3 md:mr-6 mt-5 md:mt-0">
          <h1 class="text-xl md:text-3xl text-gray-900 font-semibold opacity-80">
            Volunteer Opportunities
          </h1>
          <p class="text-base md:text-lg text-gray-900 opacity-80 mt-5">
            At Raitagyana, we offer diverse opportunities for volunteers to make
            a meaningful impact. Whether you're skilled in designing training
            materials, creating engaging content for social media, or assisting
            with administrative tasks like data analysis and logistics
            coordination, there's a role for you. Your contribution, whether
            remote or on-site, directly supports our mission of empowering
            marginalized farmers in rural India.
          </p>
        </div>
      </section>

      {isMobile ? (
        <section class="grid grid-cols-1 md:grid-cols-2 mt-10 md:mt-20  mx-5 md:mx-10 rounded-lg shadow-2xl">
          <div class="flex justify-center">
            <img
              class="w-full h-[250px] md:h-[370px]  object-cover rounded-tr-lg rounded-tl-lg "
              src={volunteer2}
              alt=""
            />
          </div>
          <div class="flex flex-col justify-center 2xl:ml-20 ml-3 md:ml-6 mr-3 md:mr-6 mt-5 md:mt-0">
            <h1 class="text-xl md:text-3xl text-gray-900 font-semibold opacity-80">
              Join Our Mission
            </h1>
            <p class="text-base  md:text-lg text-gray-900 opacity-80 mt-5">
              Ready to join our mission and make a difference? Click the "Join
              Us" button below to begin your journey with Raitagyana. We welcome
              passionate individuals from around the world who share our
              commitment to sustainable agriculture and empowering rural
              communities. Your dedication and skills can help transform the
              lives of farmers and contribute to building a resilient future for
              rural India.
            </p>
            <div className="flex justify-end mb-6">
              <button onClick={handleJoin} className="JoinMissonButton">
                Join
              </button>
            </div>
          </div>
        </section>
      ) : (
        <section class="grid grid-cols-1 md:grid-cols-2 mt-10 md:mt-20  mx-5 md:mx-10 rounded-lg shadow-2xl">
          <div class="flex flex-col justify-center 2xl:ml-20 ml-3 md:ml-6 mr-3 md:mr-6 mt-5 md:mt-0">
            <h1 class="text-xl md:text-3xl text-gray-900 font-semibold opacity-80">
              Join Our Mission
            </h1>
            <p class="text-base  md:text-lg text-gray-900 opacity-80 mt-5">
              Ready to join our mission and make a difference? Click the "Join
              Us" button below to begin your journey with Raitagyana. We welcome
              passionate individuals from around the world who share our
              commitment to sustainable agriculture and empowering rural
              communities. Your dedication and skills can help transform the
              lives of farmers and contribute to building a resilient future for
              rural India.
            </p>
            <div className="flex justify-end">
              <button onClick={handleJoin} className="JoinMissonButton">
                Join
              </button>
            </div>
          </div>
          <div class="flex justify-center">
            <img
              class="w-full h-[250px] md:h-[370px]  object-cover rounded-br-lg rounded-tr-lg"
              src={volunteer2}
              alt=""
            />
          </div>
        </section>
      )}

      <section class="grid grid-cols-1 md:grid-cols-2 mt-10 mb-20 md:mt-20  mx-5 md:mx-10 rounded-lg shadow-2xl">
        <div class="flex justify-center">
          {isMobile ? (
            <img
              class="w-full h-[250px] md:h-[370px] object-cover rounded-tl-lg rounded-tr-lg"
              src={volunteer3}
              alt=""
            />
          ) : (
            <img
              class="w-full h-[250px] md:h-[370px] object-cover rounded-tl-lg rounded-bl-lg"
              src={volunteer3}
              alt=""
            />
          )}
        </div>
        <div class="flex flex-col justify-center ml-3 mb-3 md:ml-6 mr-3 md:mr-6 mt-5 md:mt-0">
          <h1 class="text-xl md:text-3xl text-gray-900 font-semibold opacity-80">
            Volunteer Guidelines
          </h1>
          <p class="text-base md:text-lg text-gray-900 opacity-80 mt-5">
            Please note that while we welcome all volunteer inquiries,
            submitting a request does not guarantee approval. We prioritize
            serious and dedicated individuals who are aligned with our mission
            and willing to contribute their time and expertise. By volunteering
            with Raitagyana, you become part of a community dedicated to
            creating positive change in the agricultural sector and uplifting
            marginalized farmers. Join us in making a tangible difference in the
            lives of those we serve.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Volunteer;
