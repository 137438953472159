import React, { Suspense, lazy } from "react";
import "tailwindcss/tailwind.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navber/Navbar";
// import Navbar2 from "./components/navber/Navbar2";
import Footer from "./components/Footer/Footer";
import Volunteer from "./components/Volunteer/Volunteer";

const Home = lazy(() => import("./components/Home/Home"));
const ContanctUsPage = lazy(() =>
  import("./components/ContactUsPage/ContanctUsPage")
);
const Impact = lazy(() => import("./components/Impact/Impact"));
const AboutUsPage = lazy(() => import("./components/AboutUsPage/AboutUsPage"));
const Gallery = lazy(() => import("./components/Gallery/Gallery"));

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contactUs" element={<ContanctUsPage />} />
            <Route path="/aboutus" element={<AboutUsPage />} />
            <Route path="/impact" element={<Impact />} />
            <Route path="/volunteer" element={<Volunteer />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
