import React, { useState } from "react";
import { CalendarDaysIcon, HandRaisedIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Subscribe = () => {
  const [email, setEmail] = useState("");

  async function handleSubscribe() {
    console.log(email);
    const gmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;

    if (!gmailRegex.test(email)) {
      return toast.error(
        `Failed to subscribe. Please provide a valid Gmail address`
      );
    }

    try {
      const res = await axios.post(
        "https://raitagyana-backend.onrender.com/subscribe",
        { email }
      );
      console.log(res);
      toast.success("Subscription successful!");
      setEmail("");
    } catch (error) {
      console.log(error);

      toast.error(`Failed to subscribe. ${error.response.data.error}`);
    }
  }

  return (
    <div className="relative isolate overflow-hidden  pb-16 sm:py-16 lg:py-16 md:px-6  ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-[1.3rem] font-bold tracking-tight text-white sm:text-4xl">
              Subscribe to our newsletter.
            </h2>
            <p className="mt-4 text-sm sm:text-lg leading-8 text-gray-300">
              Subscribe for updates and exclusive content. Stay informed with
              the latest news and insights.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                value={email}
                required
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={handleSubscribe}
              >
                Subscribe
              </button>
            </div>
          </div>
          <dl className="grid grid-cols-2 sm:grid-col-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <CalendarDaysIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold text-white">Weekly articles</dt>
              <dd className="mt-2 text-[0.75rem] sm:text-lg leading-7 text-white opacity-80">
                Dive into weekly articles offering insightful perspectives on
                various topics.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <HandRaisedIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </div>
              <dt className="mt-4 font-semibold text-white">No spam</dt>
              <dd className="mt-2 text-[0.75rem] sm:text-lg leading-7 text-white opacity-80">
                We promise no spam! Expect only valuable updates without
                inundating your inbox.
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default Subscribe;
