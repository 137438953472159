import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logonew.png";
import { RiMenu2Fill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import "./Navbar.css";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menu, setMenu] = useState(false);
  const location = useLocation(); // React Router's useLocation hook
  const [activeLink, setActiveLink] = useState(""); // State to track active link

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const pathname = location.pathname; // Get current pathname
    const links = [
      "",
      "aboutus",
      "impact",
      "gallery",
      "volunteer",
      "contactUs",
    ]; // List of link addresses
    const match = links.find((link) => `/${link}` === pathname); // Check if current pathname matches any link

    if (match) {
      setActiveLink(match.replace("/", ""));
    } else {
      setActiveLink(""); // Reset active link if current location doesn't match any link
    }
  }, [location.pathname]); // Trigger effect when pathname changes

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 30);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const toggleMenuBar = () => {
    setMenu(!menu);
  };

  const hideNavItems = () => {
    setMenu(false);
  };

  return (
    <div
      className={`scrolled max-w-[1800px] w-[100%]  responsive-header   
      ${menu ? "bg-white max-h-max" : ""} fixed top-0 z-20`}
    >
      <header className="h-full w-full  flex   justify-between items-center">
        <div className="flex flex-row justify-center">
          <div className="flex justify-center items-center">
            <Link to="/">
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {}}
                src={logo}
                alt=""
                className={`${
                  isMobile
                    ? "w-[40px] h-[40px]"
                    : "w-[80px] h-[80px] nav-logo-img"
                }  `}
              />
            </Link>
            <Link to="/">
              {isMobile ? (
                <span
                  className={`${
                    isMobile
                      ? "text-green-600 text-2xl font-semibold"
                      : "text-green-600 text-3xl font-semibold"
                  }`}
                >
                  RAITAGYANA
                </span>
              ) : (
                <div className="flex flex-col justify-center 2xl:mt-3 ">
                  <span
                    className={`${
                      isMobile
                        ? "text-green-600 text-2xl font-semibold"
                        : "text-green-600 text-3xl font-semibold"
                    }`}
                  >
                    RAITAGYANA
                  </span>
                  <p className="text-[12px]  opacity-80 font-semibold">
                    Transforming Farms,Transforming Lives...
                  </p>
                </div>
              )}
            </Link>
          </div>
        </div>
        {isMobile && menu && (
          <RxCross2
            cursor="pointer"
            color="black"
            size={25}
            onClick={toggleMenuBar}
          />
        )}
        {isMobile && !menu && (
          <RiMenu2Fill
            cursor="pointer"
            size={25}
            color={isScrolled ? "black" : "black"}
            onClick={toggleMenuBar}
          />
        )}
        <ul
          onClick={hideNavItems}
          className={
            isMobile
              ? `flex flex-col gap-2 nav-bg py-3 w-full  absolute h-max top-[50px] ${
                  !menu
                    ? "-right-[100%] "
                    : "right-0 transition-all .5s ease-in"
                } items-center  text-lg cursor-pointer scrolled`
              : `flex justify-between items-center gap-10 nav-text text-black`
          }
        >
          <Link
            to="/"
            className={`nav-item font-semibold opacity-[85%] ${
              activeLink === "" ? "active" : ""
            }`}
          >
            Home
          </Link>

          <Link
            to="/aboutus"
            className={`nav-item font-semibold opacity-[85%] ${
              activeLink === "aboutus" ? "active" : ""
            }`}
          >
            About us
          </Link>
          <Link
            to="/impact"
            className={`nav-item font-semibold opacity-[85%] ${
              activeLink === "impact" ? "active" : ""
            }`}
          >
            Impact
          </Link>
          <Link
            to="/gallery"
            className={`nav-item font-semibold opacity-[85%] ${
              activeLink === "gallery" ? "active" : ""
            }`}
          >
            Gallery
          </Link>

          <Link
            to="/volunteer"
            className={`nav-item font-semibold opacity-[85%] ${
              activeLink === "volunteer" ? "active" : ""
            }`}
          >
            Volunteer
          </Link>

          <Link
            to="/contactUs"
            className={`nav-item font-semibold opacity-[85%] ${
              activeLink === "contactUs" ? "active" : ""
            }`}
          >
            Contact us
          </Link>
        </ul>
      </header>
    </div>
  );
};

export default Navbar;
